.bid-page {
  padding: 20px;
}

.bid-page .add-bid-button {
  margin-bottom: 20px;
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.bid-page .add-bid-button:hover {
  background-color: #45a049;
}

.bid-page .active-status {
  color: green;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  transition: transform 0.2s ease;
}

.bid-page .active-status:hover {
  transform: scale(1.1);
}

.bid-page .inactive-status {
  color: red;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  transition: transform 0.2s ease;
}

.bid-page .inactive-status:hover {
  transform: scale(1.1);
}

.bid-page img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.bid-page .delete {
  color: red;
  margin-left: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.bid-page .delete:hover {
  color: #b30000;
}

.bid-page .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.bid-page .header h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

@media (max-width: 768px) {
  .bid-page .add-bid-button {
    width: 100%;
    padding: 12px;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .bid-page {
    padding: 10px;
  }

  .bid-page .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .bid-page .add-bid-button {
    margin-top: 10px;
    width: 100%;
  }
}
