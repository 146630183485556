.dashboard {
    padding: 20px;
    font-family: Arial, sans-serif;
    margin-top: 30px;
}

h1, h2 {
    text-align: center;
}

.date-range {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.date-range label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date-range input {
    padding: 5px;
    font-size: 16px;
    margin-top: 5px;
}

.date-range button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
}

.report-summary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;
}

.summary-item {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    flex: 1 1 calc(33% - 20px);
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tables-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
}

.table-wrapper {
    flex: 1 1 calc(33% - 20px);
    background: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .summary-item {
        flex: 1 1 calc(50% - 20px);
        font-size: 14px;
    }

    h1, h2 {
        font-size: 20px;
    }

    .tables-container {
        flex-direction: column;
    }

    .table-wrapper {
        flex: 1 1 100%;
    }

    .table th, .table td {
        font-size: 14px;
    }
}

/* Extra Small Mobile Styles */
@media (max-width: 480px) {
    .summary-item {
        flex: 1 1 100%;
        font-size: 12px;
    }
.dashboard{align-items: start;
    justify-content: start;
    padding-left: 0px;
    margin-left: 0px;
}
    h1, h2 {
        font-size: 18px;
    }

    .table th, .table td {
        font-size: 12px;
    }
}
