/* AddAdvertisements.css */

.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 20px;
    background-color: #f4f4f4;
    
  }
  
  .advertisement-form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 600px; /* Reduced max-width for a narrower form */
  }
  
  .form-group {
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .form-group input[type="text"],
  .form-group input[type="file"],
  .form-group select {
    width: 90%; /* Reduced width for input fields */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-group input[type="file"] {
    padding: 30px; /* Adjust padding for file input */
  }
  
  .image-preview {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .image-preview img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .form-actions {
    text-align: start;
    padding-left: 20px;
  }
  
  .form-actions button {
    background-color: #4CAF50; 
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

  }
  
  .form-actions button:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 780px) {
   
.form-container {
  
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4;
  
}

.advertisement-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 600px; /* Reduced max-width for a narrower form */
}

.form-group {
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.form-group input[type="text"],
.form-group input[type="file"],
.form-group select {
  width: 90%; /* Reduced width for input fields */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group input[type="file"] {
  padding: 30px; /* Adjust padding for file input */
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.form-actions {
  text-align: start;
  padding-left: 20px;
}

  }