.sidebar {
  background-color: #f0f0f0;
  width: 240px;
  position: fixed;
  top: 64px; /* Adjust top value to match Header height */
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding: 20px;
}

.h1 {
  padding-left: 12px;
  color: #0056b3;
}

.menu {
  list-style-type: none;
  padding: 10px;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
}

.menu-item {
  margin-bottom: 10px;
}

.menu-link {
  color: #0056b3;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
}

.menu-link:hover {
  background-color: #333;
  color: white;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .drawer-content .menu {
    list-style-type: none;
    padding: 10px;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
  }

  .drawer-content .menu-item {
    margin-bottom: 10px;
  }

  .drawer-content .menu-link {
    color: #0056b3;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
  }

  .drawer-content .menu-link:hover {
    background-color: #333;
    color: white;
  }
}


@media (min-width: 480px) {
  .main-content {
    margin-left: 20px; /* Adjust to the sidebar width */
    padding: 20px;
  }
}
