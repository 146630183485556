.customer-details-container {
  /* max-width: 900px; */
  margin: 22px auto;
  padding-top: 20px;
  /* padding-left: 20px;
  padding-right: 20px; */
  /* background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.customer-details-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.customer-section {
  justify-content: start;
  margin-bottom: 20px;
}

.customer-section h2 {
  border-bottom: 2px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 10px;
  color: #444;
  padding: 10px;
  border-radius: 5px;
}

.customer-details {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 20px;
}

.customer-details p {
  font-size: 16px;
  margin: 5px 0;
  color: #555;
}

.customer-details p strong {
  color: #333;
}

.customer-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
}

.customer-images div {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.customer-images img {
  width: 100%;
  max-width: 200px;
  cursor: pointer;
  margin: 10px 0;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modalContent {
  position: relative;
  width: 30%; /* Adjust width as needed */
  height: 60%; /* Adjust height as needed */
  overflow: hidden;
  background-color: #fff; /* Set a background color for visibility */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensure the image fits within the modal */
}

.downloadButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  color: green;
}

.downloadButton:hover {
  background-color: #f0f0f0;
}
/* CustomerDetails.css */

/* Mobile responsive styles */
@media (max-width: 768px) {
  .customer-details-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .customer-details-container h1 {
    font-size: 24px; /* Reduced font size */
  }

  .customer-section h2 {
    font-size: 20px; /* Reduced font size */
  }

  .customer-details p {
    font-size: 14px; /* Reduced font size */
  }

  .customer-details p strong {
    font-size: 14px; /* Reduced font size */
  }

  .customer-images img {
    max-width: 150px; /* Reduced image size */
  }

  .customer-details {
    flex-direction: column; /* Stack elements vertically */
    overflow: visible; /* Remove horizontal overflow */
  }
}

@media (max-width: 480px) {
  .customer-details-container {
    padding-left: 20px;
    padding-right: 10px;
    max-width: 1000px;
    min-width: 600px;
  }

  .customer-details-container h1 {
    font-size: 20px; /* Further reduced font size */
  }

  .customer-section h2 {
    font-size: 18px; /* Further reduced font size */
  }

  .customer-details p {
    font-size: 12px; /* Further reduced font size */
  }

  .customer-details p strong {
    font-size: 12px; /* Further reduced font size */
  }

  .customer-images img {
    max-width: 120px; /* Further reduced image size */
  }

  .customer-details {
    flex-direction: column; /* Stack elements vertically */
    overflow: visible; /* Remove horizontal overflow */
  }
}

/* Ensure images and sections take up full width on mobile */
.customer-details img {
  width: 100%;
  height: auto;
}

.customer-section {
  width: 100%;
}
