.product {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  justify-content: end;
  overflow: scroll; /* Ensure content can scroll if necessary */
}

.product h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}
.delete{
  display: inline;
  background-color: red;
  /* color: red; */
}
.product .button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.product .button-container .search-container {
  flex-grow: 1;
  margin-right: 10px;
}

.product .button-container .search-container input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.product .button-container .search-container button {
  padding: 10px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.product .button-container .add-product-btn {
  padding: 10px 16px;
  font-size: 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.product .data-table-container {
  margin-top: 20px;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent text wrapping */
}

.product .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.product .pagination button {
  margin: 0 5px;
}

@media (max-width: 768px) {
  .product {
    padding-left: 150px;
    overflow: scroll;
  }

  .product h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .product .button-container {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 10px;
  }

  .product .button-container .search-container {
    margin-bottom: 10px;
  }

  .product .button-container .search-container input[type="text"] {
    font-size: 14px;
    padding: 8px;
  }

  .product .button-container .search-container button,
  .product .button-container .add-product-btn {
    font-size: 14px;
    padding: 8px 14px;
  }

  .product .pagination button {
    margin: 0 3px;
  }

  .product .data-table-container {
    overflow-x: scroll; /* Ensure horizontal scrolling for smaller screens */
  }
}