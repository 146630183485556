.orders {
  width: 100%;
  overflow: scroll;
  margin: 20px;
}
.orders h1{
  text-align: left;
}




@media (max-width: 768px) {
  .orders {
   
padding-left: 350px;
right:20px ;
  }
} 

@media (max-width:480px) {
  .orders{
    left: 20px;
  }
  
}
/* }
.search-container {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.search-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 20px;
}
*/