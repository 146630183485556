/* AddForm.css */

.product-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4;
}

.product-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 800px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  margin-right: 20px;
  margin-bottom: 20px;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="file"],
.form-group select,
.form-group input[type="checkbox"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group input[type="checkbox"] {
  width: auto;
  align-self: flex-start; /* Align checkbox to the start */
}


.form-description,
.form-images {

  margin-bottom: 20px;
}

.form-description .form-group {
  margin-bottom: 0;
  height: 200px; /* Increase height here */
}

.form-description .form-group .quill {
  height: 50%;
}

.form-images .form-group {
  margin-bottom: 0;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.image-preview img {
  width: 100px;
  /* height: 100px; */
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
 
}

.form-actions {
  text-align: start;
}

.form-actions button {
  background-color: #4CAF50; 
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* width: 100%; */
}

.form-actions button:hover {
  background-color: #45a049;
}

/* AddAdvertisements.css */

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4;
}

.advertisement-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 600px; /* Reduced max-width for a narrower form */
}

.form-group {  margin-top: 20px;
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.form-group input[type="text"],
.form-group input[type="file"],
.form-group select {
  width: 80%; /* Reduced width for input fields */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group input[type="file"] {
  padding: 3px; /* Adjust padding for file input */
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.form-actions {  padding-top: 30px;
  text-align: center;
}

.form-actions button { 
  padding-top: 20px;
  background-color: #007BFF;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

}
.button{
  height: min-content;
}
.form-actions button:hover {
  background-color: #0056b3;
}

/* Additional styles for cost price, discount percentage, and stock to show inside the card */
.product-form .form-group-half {
  flex: 0 0 48%;
}

.product-form .form-group-half input[type="text"],
.product-form .form-group-half input[type="number"] {
  width: 100%;
}
