.data-table-container {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  margin: 0 auto;
}

.data-table-wrapper {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.data-table th, .data-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.data-table th {
  background-color: #f8f9fa;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: end;
  align-items:center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
}

.pagination span {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .pagination {
    justify-content: center;
  }
  
  .data-table th, .data-table td {
    padding: 8px; /* Reduce padding for mobile view */
    font-size: 12px; /* Adjust font size for mobile view */
  }

  .data-table-container {
    overflow-x: auto; /* Ensure horizontal scrolling for the container */
  }
  
  .data-table-wrapper {
    overflow-x: auto; /* Ensure horizontal scrolling for the wrapper */
  }
}
