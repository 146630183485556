.category-listing {
  padding: 20px;
  margin-top: 50px;
}

.category-listing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.category-listing-title {
  font-size: 24px;

  font-weight: bold;
}

.category-listing-buttons {
  display: flex;
  gap: 10px; /* Add some space between the buttons */
}

.category-listing-buttons button {
  padding: 10px 15px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.category-listing-buttons button:hover {
  background-color: #0056b3;
}

.active-status {
  color: green;
}

.inactive-status {
  color: red;
}

@media (max-width: 768px) {
  .category-listing {
    padding: 10px;
  }

  .category-listing-header {
    flex-direction: column;
    align-items: center;
  }

  .category-listing-buttons {
    flex-direction: row;
    gap: 10px;
  }

  .category-listing-title {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .category-listing {
    padding: 5px;
  }

  .category-listing-title {
    font-size: 18px;
  }

  .category-listing-buttons {
    flex-direction: row;
    gap: 3px;
    justify-content: flex-end; /* Align buttons to the right */
  }
}

.empty-list-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px); /* Adjust based on header and padding */
  font-size: 20px;
  color: #777;
  text-align: center;
}
