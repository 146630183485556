.contact{
   width: 100%;  
}.contact h1{
  text-align: left;
}
@media (max-width: 768px) {
    .contact {
      padding-left: 150px;
     
    }
  }