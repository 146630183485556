.customer{
    max-width: 1200px;
    /* margin: 20px auto; */
    /* padding: 20px; */
    
    overflow: scroll;
}.customer h1{
    text-align: left;

}
@media (max-width: 768px) {
    .customer {
      padding-left: 50px;
     
    }
  }


@media (min-width: 480px) {
    .customer {
        /* padding-left: 220px; */
    }
  }