/* Base styles */

body {
  font-family: Arial, sans-serif;
  margin: 0; /* Reset default margin */
  padding: 0;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  min-height: 100vh; /* Ensure full viewport height */
}

* {
  box-sizing: border-box;
}

/* Login container */

.login-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Login form */

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
}

/* Input focus styles */

input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #66afe9;
  outline: none;
}

/* Login button */

button[type="submit"] {
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
}

button[type="submit"]:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

/* Error message (optional) */

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

/* Media Queries (optional) */

@media only screen and (max-width: 768px) {
  .login-container {
    width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .login-container {
    margin: 0;
    width: 100%;
  }
}
