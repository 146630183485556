.bid-page {
    padding: 20px;
  }
  
  .bid-page .add-bid-button {
    margin-bottom: 20px;
  }
  
  .bid-page .active-status {
    color: green;
    font-weight: bold;
    cursor: pointer;
  }
  
  .bid-page .inactive-status {
    color: red;
    font-weight: bold;
    cursor: pointer;
  }
  
  .bid-page img {
    max-width: 100px;
    max-height: 100px;
  }
  
  .bid-page .delete {
    color: red;
    margin-left: 10px;
  }
  