/* Category.css */

.category-form-container {
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100vh;
  margin-top: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  /* Optional: to give a subtle background color */
}

.category-form {
  
  /* Increase max-width to make the form wider */
  background: #fff;
  padding: 10px;
  /* Increase padding for more spacing inside the form */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
min-width: 500px;
  max-width: 900px;
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {

  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  display: flex;
  justify-content: flex-start;
}

.form-actions button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button:hover {
  background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
  .category-form {
    min-width: 400px;
    max-width: 600px;
    /* Increase max-width to make the form wider */
    background: #fff;
    /* Increase padding for more spacing inside the form */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    /* Reduce padding for smaller screens */
  }


  .form-actions {
    display: flex;
    justify-content: flex-start;
  }

  .form-actions button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
}