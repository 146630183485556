/* src/css/CategoryListing.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    
  }
  
.title{
    margin: 0;
    flex-grow: 1;
  }
  
  button {
   
    background-color: #4CAF50; 
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px 20px 10px;
    cursor: pointer;
  }
  
  .add-category-button:hover {
    background-color: #45a049;
  }
  