.payments {
  margin-top: 50px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: scroll;
}

.payments h1 {
  text-align: left;
}

.paymentImage {
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modalContent {
  position: relative;
  width: 30%; /* Adjust width as needed */
  height: 60%; /* Adjust height as needed */
  overflow: hidden;
  background-color: #fff; /* Set a background color for visibility */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensure the image fits within the modal */
}

.downloadButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  color: green;
}

.downloadButton:hover {
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .payments {
    margin-top: 20px;
    padding-left: 350px; /* Adjust padding as needed */
    overflow: scroll;
    justify-content: start;
    overflow-x: scroll;
    overflow-y: scroll;
  }
  
  .payments h1 {
    align-content: flex-start;
  }
}
