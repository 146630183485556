.appBar {
  background-color: #007bff;
  z-index: 1300;
}

.drawer-content {
  width: 240px;
}

.drawer-content .menu {
  list-style-type: none;
  padding: 10px;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
}

.drawer-content .menu-item {
  margin-bottom: 10px;
}

.drawer-content .menu-link {
  color: #0056b3;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
}

.drawer-content .menu-link:hover {
  background-color: #333;
  color: white;
}

@media (max-width: 768px) {
  .appBar {
    width: 100%;
    overflow: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 150px;
    overflow-x: scroll;
    overflow-y: scroll;

  }

  h1 {
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .appBar {
    overflow: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 5px;
    overflow-x: scroll;
    overflow-y: scroll;

  }

  h1 {
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
  }

  .drawer-content {
    width: 200px;
  }
}