/* Adjusted styles for OrderDetail component */

.orderDetailContainer {
  max-width: 1900px; /* Increased max-width for better layout */
  margin: auto;
  padding: 20px;
  font-weight: bold;
}

.orderDetailPaper {
  padding: 20px;
  margin-bottom: 20px;
}

.section {
  font-weight: bold;
  margin-bottom: 20px;
}

.divider {
  margin: 20px 0;
}

.tableContainer {
  margin-top: 20px;
  overflow-x: auto;
}

.tableContainer th,
.tableContainer td {
  padding: 10px;
}

@media (max-width: 768px) {
  .orderDetailContainer {
    padding: 10px; /* Adjust padding for smaller screens */
    max-width: 100%; /* Ensure full width on smaller screens */
  }
}
