body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.layout {
  display: flex;
}

.mainContent {
  margin-left: 240px;
  /* margin-top: 64px; */
  padding: 20px;
  flex: 1;
  overflow-x: auto;
  min-height: calc(100vh - 64px);
}

@media (max-width: 768px) {
  .mainContent {
    width: 100%;
    overflow: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding-left: 150px; */
    overflow-x: scroll;
    overflow-y: scroll;

  } h1{
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
  }
}


@media (max-width: 480px) {
  .mainContent {
    width: 100%;
    overflow: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 0px;
    overflow-x: scroll;
    overflow-y: scroll;

  } h1{
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 20px;
  }
}
