
  .blog{
    padding-top: 30px;
align-items: flex-start;
float: right;
  }
  @media (max-width: 768px) {
    .blog{
      padding: auto;
    }
  }

  @media (max-width: 480px) {
    .blog{
      
      padding-left: 0px;
      align-items: flex-start;
      justify-content: start;
    }
  }
  .blog-form-container {
    max-width: 800px;
    margin-top: 10px;
    padding: 20px;
  }
  
  .blog-form {
    display: flex;
    flex-direction: column;
   
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
   
  }
  
  .form-group label {
    font-weight: bold;
  }
  
  .image-preview {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .image-container {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .image-container button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 2px 8px;
    cursor: pointer;
  }
  
.paymentImage {
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
}
  .form-actions {
    margin-top: 20px;
    display: flex;
    justify-content:start;
   
  }
  
  .error-message {
    background-color: red;
    color: red;
    font-weight: bold;
  }
  